<template>
  <div class="hello">
    <b-card-group>
      <b-card-body>
        <b-card
          header="Home Page"
          header-tag="header"
          title="Welcome to n4mmon"
          title-tag="title"
          img-src="./../assets/home/home.png"
          img-alt="N4MMON"
          img-top
        >
          <b-card-text>
            Welcome to nammon's Website Page but Noting in Here <br>
            Love You So much <br>
            You can follow me this
          </b-card-text>
            <b-button squared to="/facebook" variant="outline-primary">
              Facebook
            </b-button>
            <b-button squared to="/instagram" variant="outline-warning" >
              Instagram
            </b-button>
            <b-button squared to="/youtube" variant="outline-danger">
              Youtube
            </b-button>
        </b-card>
      </b-card-body>
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
